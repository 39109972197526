<template>
  <div class="container-fluid">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="px-4 pt-2 pb-5">
      <div class="bg p-3">
        <div class="d-flex justify-content-between mb-3">
          <h4 class="text-dark">Liste des faqs</h4>
          <form class="form-inline">
            <div class="form-group mr-4"></div>
            <!-- <div class="form-group">
              <input type="text" class="form-control" name="" placeholder="Rechercher">
            </div> -->
            <div class="form-group mr-md">
              <a
                @click.prevent="goTo({ name: 'espace.admin.faqs.edit' })"
                class="btn btn-warning"
                >Ajouter</a
              >
            </div>
          </form>
        </div>
        <b-card no-body>
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              borderless
              :th-class="['border-bottom', 'border-top']"
              striped
              hover
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex">
                  <a
                    href="#"
                    @click.prevent="editRole(data.item)"
                    title="Modifier"
                    class="text-secondary mr-2"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a href="#" title="Supprimer" class="text-danger mr-2"
                    ><i class="fa fa-trash-alt"></i
                  ></a>
                  <!-- <a href="#" title="Détail" class="text-info mr-2"><i class="fa fa-eye"></i></a> -->
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
        </b-card>
        <div
          class="d-flex justify-content-between align-items-center mt-4"
          v-if="roles && roles.length"
        >
          <p>{{ roles.length }} éléments sur {{ roles.length }}</p>
          <nav class="">
            <ul class="pagination justify-content-end">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      activeRole: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Roles",
          active: true,
        },
      ],
    };
  },
  created() {
    this.fetchRoles();
  },
  computed: {
    ...mapGetters({
      roles: "role/roles",
    }),
    tableFields() {
      return [
        "index",
        { key: "name", label: "Libellé" },
        // { key: 'structure', label: 'Structure' },
        "actions",
      ];
    },
    tableData() {
      return this.roles.map((item) => ({ ...item }));
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    ...mapActions({
      fetchRoles: "role/fetchRoles",
      createOrUpdateRole: "role/createOrUpdateRole",
    }),
    editRole(role) {
      console.log(role);
      this.activeRole = { ...role };
      this.$refs["Roleditor"].show();
    },
    updateOrCreateRole(role) {
      console.log(role);
      this.createOrUpdateRole(role).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
